import InputMask from "react-input-mask";
import { Input } from "antd";

function MaskInput({ value = "", onChange, mask }) {

  return (
    <InputMask mask={mask} value={value} maskChar="" onChange={onChange}>
      {(props) => {
        return <Input size="large" {...props}/>
      }}
    </InputMask>
  )
}

export default MaskInput;
