import { Badge, Dropdown, Empty } from "antd";
import NotificationsIcon from "../../../../assets/svg/NotificationsIcon";
import NotificationsIconWhite from "../../../../assets/svg/NotificationsIconWhite";

function BadgeNotification() {

  const notificationBody = (
    <div className="w-80 shadow-md">

      <div className="p-4 text-white" style={{ backgroundColor: "#440099" }}>
        <div className="flex justify-between">
          <div className="flex items-center">
            {NotificationsIconWhite}
            <p className="m-0 ml-3 uppercase font-medium">Notificações</p>
          </div>
          <div>
            {/*<p className="m-0">2 novas</p>*/}
          </div>
        </div>
      </div>

      <div className="bg-white">

        {/*<div className="p-5 mb-1">*/}
        {/*  <p className="mb-1 text-xs">08/05/2021</p>*/}
        {/*  <p className="m-0 font-medium text-base">Proposta encaminhada para análise</p>*/}
        {/*  <p className="m-0 text-xs">Clique aqui para ver negócio</p>*/}
        {/*</div>*/}

        <div className="p-5 mb-1">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sem notificações" />
        </div>

      </div>

    </div>
  );

  return (
    <div className="mr-3 flex">
      <Dropdown overlay={notificationBody} className="cursor-pointer" trigger="click">
        <Badge count={0} dot>
          {NotificationsIcon}
        </Badge>
      </Dropdown>
    </div>
  )
}

export default BadgeNotification;
