import DealTabRoutes from "./DealTabRoutes";

function DealTab({ customerId }) {

  return (
    <div>
      <DealTabRoutes customerId={customerId} />
    </div>
  )
}

export default DealTab;
