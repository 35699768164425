import { Avatar, Card, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

function CardSales(props) {
  const { backgroundColorAvatar, colorAvatar, icon, title, value, classes } = props;

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div>
      <Card style={{ width: 300, marginTop: 16, backgroundColor: "#f3f4f6", borderRadius: "0.5rem" }} className={classes}>
        <div className="flex">
          <Avatar style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: backgroundColorAvatar,
            color: colorAvatar,
            border: "1px solid #e5e7eb"
          }}
                  size={64}
                  icon={icon} />
          <div className="ml-5">
            <p className="font-medium mb-2 text-gray-600">{title}</p>
            <p className="m-0 text-xl font-bold text-gray-700">{value !== null ? value : <Spin indicator={antIcon} />}</p>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default CardSales;
