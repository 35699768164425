import { combineReducers, createStore } from "redux";
import AuthReducer from './reducers/AuthReducer';
import UserReducer from "./reducers/UserReducer";

const storeConfig = () => {
  return createStore(
    combineReducers({
      authStore: AuthReducer,
      userStore: UserReducer,
    }),
  );
}

export default storeConfig;
