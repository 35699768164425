import { Badge, Button, Table } from "antd";
import {useContext, useEffect, useState} from "react";
import PartnerHttp from "../../../../../../../../http/requests/PartnerHttp";
import EditIcon from "../../../../../../../../assets/svg/EditIcon";
import { useHistory, useRouteMatch } from "react-router-dom";
import DealContext from "../../DealContext";

function DealList(props) {
  // const [dealOpportunities, setDealOpportunities] = useState([]);
  const [openDealOpportunitiesData, setOpenDealOpportunitiesData] = useState([]);
  const [closedDealOpportunitiesData, setClosedDealOpportunitiesData] = useState([]);
  const [openDealLoading, setOpenDealLoading] = useState(true);
  const [closedDealLoading, setClosedDealLoading] = useState(true);
  const { setDealSelected } = useContext(DealContext);
  const history = useHistory();

  const { customerId } = props;
  const { url } = useRouteMatch();

  useEffect(() => {
    PartnerHttp.getDealOpportunitiesByCustomer(customerId)
      .then(res => {
        // setDealOpportunities(res.data);
        const openData = res.data.filter(elem => elem.status !== 'closed').map(elem => (
          {
            createdDate: elem.created_at,
            productType: elem.product_type.name,
            value: elem.desired_amount,
            status: elem.status,
            buttons: elem
          }
        ));
        setOpenDealOpportunitiesData(openData);
        setOpenDealLoading(false);
        const closedData = res.data.filter(elem => elem.status === 'closed').map(elem => (
          {
            createdDate: elem.created_at,
            productType: elem.product_type.name,
            value: elem.desired_amount,
            status: elem.status,
            buttons: elem
          }
        ));
        setClosedDealOpportunitiesData(closedData);
        setClosedDealLoading(false);
      });
  }, [ customerId ]);

  const goToInfo = (deal) => {
    setDealSelected(deal);
    history.push(url + `/deal-opportunity/${deal.id}`);
  }

  const columns = [
    {
      title: 'DATA DE CRIAÇÃO',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: date => {
        return date.split('T')[0].split('-').reverse().join('/');
      }
    },
    {
      title: 'TIPO DE PRODUTO',
      dataIndex: 'productType',
      key: 'productType',
      render: productType => <span className="underline">{productType}</span>
    },
    {
      title: 'VALOR',
      dataIndex: 'value',
      key: 'value',
      render: value => {
        return "R$ " + value;
      }
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: '',
      dataIndex: 'buttons',
      key: 'buttons',
      render: deal => {
        return (
          <Button type="link" onClick={() => goToInfo(deal)}>
            {EditIcon}
          </Button>
        )
      }
    },
  ];

  return (
    <div>
      <div>
        <p className="font-bold text-lg mb-5">Abertos {" "}
          {openDealOpportunitiesData.length !== 0 ? <Badge count={openDealOpportunitiesData.length} size="small" /> : ""}
        </p>
        <Table columns={columns} dataSource={openDealOpportunitiesData} loading={openDealLoading}/>
      </div>

      <div>
        <p className="font-bold text-lg mb-5">Finalizados {" "}
          {closedDealOpportunitiesData.length !== 0 ? <Badge count={closedDealOpportunitiesData.length} size="small" /> : ""}
        </p>
        <Table columns={columns} dataSource={closedDealOpportunitiesData} loading={closedDealLoading} />
      </div>
    </div>
  )
}

export default DealList;
