const BalanceReceivableIconGreen = (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
       width="1em" height="1em" viewBox="0 0 383.952 383.952" enableBackground="new 0 0 383.952 383.952"
       xml="preserve">
    <g>
      <path fillRule="evenodd" clipRule="evenodd" d="M383.952,253.469c-2.546,4.963-4.731,10.157-7.702,14.852
        c-12.75,20.151-25.746,40.147-38.626,60.217c-14.081,21.938-34.126,32.924-60.204,32.92c-62.974-0.008-125.947-0.002-188.921-0.002
        c-1.352,0-2.703,0-4.51,0c0-1.595,0-2.922,0-4.25c0-39.857,0.027-79.717-0.061-119.575c-0.006-2.668,0.832-4.232,2.962-5.831
        c10.236-7.68,21.178-13.638,34.094-15.307c19.382-2.505,36.649,2.357,51.588,14.982c2.173,1.836,4.254,2.557,7.034,2.547
        c24.864-0.086,49.729-0.143,74.594-0.002c10.349,0.058,18.297,4.824,23.147,13.973c4.785,9.023,4.292,18.144-1.432,26.621
        c-5.425,8.035-13.295,11.838-22.971,11.845c-22.366,0.018-44.731-0.035-67.098,0.028c-8.563,0.024-14.109,7.798-11.021,15.337
        c1.829,4.463,5.326,7.062,10.119,7.076c27.363,0.084,54.734,0.396,82.087-0.158c14.004-0.284,25.186-7.232,33.692-18.398
        c13.019-17.089,26.086-34.142,39.134-51.208c0.911-1.191,1.81-2.392,2.751-3.558c5.641-6.986,12.892-10.188,21.81-8.648
        c9.434,1.627,15.727,7.127,18.694,16.311c0.22,0.679,0.556,1.321,0.838,1.98C383.952,247.969,383.952,250.719,383.952,253.469z" fill="#047857"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M230.971,0c3.783,0.705,7.586,1.314,11.345,2.128
        c43.681,9.447,74.534,47.999,74.132,92.584c-0.399,44.226-32.028,82.281-75.664,91.036
        c-53.601,10.755-105.416-27.483-111.206-82.065c-5.432-51.205,31.299-97.117,82.406-103.025c0.845-0.098,1.66-0.434,2.489-0.658
        C219.973,0,225.472,0,230.971,0z M214.647,143c0,0.206-0.167,1.35,0.038,2.423c0.321,1.685,0.555,3.534,1.43,4.937
        c1.744,2.795,4.525,3.868,7.789,3.138c3.455-0.773,5.813-3.52,5.619-7.39c-0.188-3.758,0.954-5.455,4.661-6.961
        c13.536-5.501,21.018-21.197,17.049-34.837c-2.684-9.224-9.027-14.949-17.868-18.272c-1.406-0.529-3.509-1.879-3.553-2.923
        c-0.311-7.31-0.164-14.639-0.164-22.804c1.91,1.321,3.2,2.167,4.44,3.081c3.582,2.643,7.686,2.363,10.443-0.741
        c2.71-3.051,2.826-7.657-0.626-10.349c-3.458-2.697-7.556-4.682-11.595-6.482c-1.879-0.837-2.709-1.471-2.737-3.619
        c-0.073-5.329-3.004-8.473-7.439-8.43c-4.371,0.042-7.479,3.382-7.413,8.561c0.034,2.695-0.882,3.793-3.373,4.796
        c-9.282,3.74-14.385,10.972-15.545,20.766c-1.186,10.01,2.569,17.827,11.454,22.936c1.837,1.057,3.785,1.938,5.536,3.118
        c0.796,0.536,1.747,1.598,1.757,2.432c0.121,9.842,0.078,19.688,0.078,30.636c-3.618-1.733-6.612-3.04-9.485-4.573
        c-4.298-2.293-8.586-1.528-10.845,2.111c-2.251,3.629-1.243,8.28,2.996,10.544C202.619,137.942,208.287,140.143,214.647,143z" fill="#047857"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0,219.723c2.99-6.483,8.113-8.589,15.097-8.352
        c11.226,0.382,22.474,0.086,33.712,0.108c8.217,0.016,12.676,4.495,12.677,12.778c0.012,48.2-0.087,96.401,0.111,144.601
        c0.028,6.964-1.882,12.097-8.354,15.094c-14.998,0-29.996,0-44.994,0c-3.705-1.795-6.456-4.544-8.249-8.249
        C0,323.71,0,271.716,0,219.723z" fill="#047857"/>
      <path fillRule="evenodd" clipRule="evenodd" fill="#047857" d="M0,375.703c1.793,3.705,4.544,6.454,8.249,8.249
        c-2.75,0-5.5,0-8.249,0C0,381.202,0,378.453,0,375.703z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M229.94,101.047c4.308,1.771,6.666,4.649,7.151,8.938
        c0.691,6.121-1.785,10.783-7.151,14.778C229.94,116.654,229.94,109.155,229.94,101.047z" fill="#047857"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M214.441,62.611c0,5.225,0,9.738,0,14.264
        C209.337,74.929,209.086,68.47,214.441,62.611z" fill="#047857"/>
    </g>
</svg>
)

export default BalanceReceivableIconGreen;
