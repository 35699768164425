import { Col, Form, Row, DatePicker, Select } from "antd";
import TableLastSales from "./components/TableLastSales/TableLastSales";
import { useStore } from "react-redux";
import CardSales from "./components/CardSales/CardSales";
import { useEffect, useState } from "react";
import PartnerHttp from "../../../../http/requests/PartnerHttp";
import DealIconBlue from "../../../../assets/svg/DealIconBlue";
import DealIconRed from "../../../../assets/svg/DealIconRed";
import BalanceReceivableIconGreen from "../../../../assets/svg/BalanceReceivableIconGreen";
import CustomersIconYellow from "../../../../assets/svg/CustomersIconYellow";
import ProductTypeHttp from "../../../../http/requests/ProductTypeHttp";
import locale from "antd/es/date-picker/locale/pt_BR";

const { RangePicker } = DatePicker;
const { Option } = Select;

function Dashboard() {
  const store = useStore();
  const [dashboardData, setDashboardData] = useState(
    {
      balanceReceivable: null,
      customers: null,
      newSales: null,
      pendingSales: null
    }
  );
  const [salesFilters, setSalesFilters] = useState({ date: null, status: "", productsType: "" });
  const [productTypes, setProductTypes] = useState([]);
  const [productTypesLoading, setProductTypesLoading] = useState(true);

  const { name, id } = store.getState().userStore;

  const getProductTypes = () => {
    ProductTypeHttp.getAll()
      .then(res => {
        setProductTypes(res.data.data);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setProductTypesLoading(false);
      })
  }

  useEffect(() => {
    PartnerHttp.getSalesData(id)
      .then(res => {
        setDashboardData({
          balanceReceivable: "R$ " + (res.data.balanceReceivable === 0 ? '--' : res.data.balanceReceivable),
          customers: res.data.customers === 0 ? '--' : res.data.customers,
          newSales: res.data.newSales === 0 ? '--' : res.data.newSales,
          pendingSales: res.data.pendingSales === 0 ? '--' : res.data.pendingSales
        });
      })
      .catch(err => {
        console.log(err);
      });
    getProductTypes();
  }, [ id ]);

  return (
    <div>

      <div className="px-10 py-5" style={{ backgroundColor: "#f0f2f5" }}>
        <h1 className="text-2xl font-semibold text-primary mb-2">Olá, { name }</h1>
        <p className="text-gray-500 text-lg">
          Acompanhe suas oportunidades no período.
        </p>
      </div>

      <div className="px-10 mt-10">
        <Form>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Período">
                <RangePicker picker="day"
                             value={salesFilters.date}
                             size="large"
                             locale={locale}
                             placeholder={["Data Inicial", "Data Final"]}
                             format={['DD/MM/YYYY', 'DD/MM/YY']}
                             onChange={(value) => setSalesFilters({ ...salesFilters, date: value })}
                             style={{ maxWidth: "300px" }} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Status">
                <Select value={salesFilters.status}
                        onChange={(value) => setSalesFilters({ ...salesFilters, status: value })}
                        style={{ maxWidth: "300px" }}
                        size="large">
                  <Option value="">Todos</Option>
                  <Option value="canceled">Cancelado</Option>
                  <Option value="analyzing">Analisando</Option>
                  <Option value="approved">Aprovado</Option>
                  <Option value="pending">Pendente</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item label="Tipo de Produto">
                <Select
                  style={{ maxWidth: "300px" }}
                  size="large"
                  loading={productTypesLoading}
                  value={salesFilters.productsType}
                  onChange={value => setSalesFilters({ ...salesFilters, productsType: value })}>
                  <Option value="">Todos</Option>
                  {productTypes.map(elem => <Option value={elem.name}>{elem.name}</Option>)}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <div className="px-10 flex justify-between flex-wrap">

        <CardSales backgroundColorAvatar="#fde68a"
                   colorAvatar="#b45309"
                   icon={CustomersIconYellow}
                   title="Total de cliente"
                   value={dashboardData.customers}/>

        <CardSales backgroundColorAvatar="#a7f3d0"
                   colorAvatar="#047857"
                   icon={BalanceReceivableIconGreen}
                   title="Saldo a receber"
                   value={dashboardData.balanceReceivable} />

        <CardSales backgroundColorAvatar="#bfdbfe"
                   colorAvatar="#1d4ed8"
                   icon={DealIconBlue}
                   title="Novas vendas"
                   value={dashboardData.newSales} />

        <CardSales backgroundColorAvatar="#fecaca"
                   colorAvatar="#b91c1c"
                   icon={DealIconRed}
                   title="Vendas pendentes"
                   value={dashboardData.pendingSales} />

      </div>

      <div className="px-10 mt-10">
        <p className="text-xl text-gray-500 font-semibold">Ultimas vendas</p>
        <TableLastSales partnerId={id} filters={salesFilters} />
      </div>

    </div>
  )
}

export default Dashboard;
