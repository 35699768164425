import { Avatar, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import PartnerHttp from "../../../../../../http/requests/PartnerHttp";
import moment from "moment";

function TableLastSales(props) {
  const [dealOpportunities, setDealOpportunities] = useState();
  const [loading, setLoading] = useState(true);

  const { partnerId, filters } = props;

  console.log(filters);

  const filteredDealOpportunities = () => {
    if(dealOpportunities){
      return dealOpportunities.filter(elem => (filters.status === "" || elem.status === filters.status))
       .filter(elem => ((filters.date === null) || ((filters.date[0] <= elem.dateMoment) && (elem.dateMoment <= filters.date[1]))))
       .filter(elem => (filters.productsType === '' || filters.productsType === elem.product));
    }
    return [];
  };
  useEffect(() => {
    PartnerHttp.getDealOpportunities(partnerId)
      .then(res => {
        if(res.data.length) {
          const data = res.data.map(elem => {
            return {
              key: elem.id,
              customer: elem.customer ? elem.customer.name : '--',
              product: elem.product_type ? elem.product_type.name : '--',
              desiredAmount: "R$ " + elem.desired_amount,
              date: elem.created_at.split('T')[0].split('-').reverse().join('/'),
              status: elem.status,
              dateMoment: moment(elem.created_at)
            }
          });
          setDealOpportunities(data);
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [ partnerId ]);

  const columns = [
    {
      title: 'CLIENTE',
      dataIndex: 'customer',
      key: 'customer',
      render: name => {
        const colorArray = ["#fbbf24", "#f87171", "#a78bfa", "#60a5fa"];
        const getAvatarColor = () => colorArray[Math.floor((Math.random() * colorArray.length))];
        const arrayNameUser = name.split(" ");
        const getInitials = () => {
          return arrayNameUser[0].substr(0, 1) +
            arrayNameUser[arrayNameUser.length - 1].substr(0, 1)
        }
        const color = getAvatarColor();
        return (
          <div className="flex items-center">
            <Avatar style={{ color: "white", backgroundColor: color, marginRight: "20px" }} size={64}>{getInitials()}</Avatar>
            <p className="text-gray-600 font-semibold m-0 uppercase text-lg">{name}</p>
          </div>
        )
      }
    },
    {
      title: 'PRODUTO',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: 'TOTAL DA VENDA',
      dataIndex: 'desiredAmount',
      key: 'desiredAmount',
    },
    {
      title: 'DATA',
      key: 'date',
      dataIndex: 'date'
    },
    {
      title: 'STATUS',
      key: 'status',
      dataIndex: 'status',
      render: status => {
        const objectFunctions = {
          canceled: () => ({ text: 'Cancelado', color: 'red' }),
          analyzing: () => ({ text: 'Analisando', color: 'blue' }),
          approved: () => ({ text: 'Aprovado', color: 'green' }),
          pending: () => ({ text: 'Pendente', color: 'gold' })
        }
        const data = objectFunctions[status]();
        return (
          <Tag color={data.color} style={{ padding: "0.25rem 1rem", borderRadius: "10000px", fontWeight: "600" }}>
            {data.text}
          </Tag>
        )
      }
    }
  ];

  return (
    <div>
      <Table columns={columns}
             style={{ border: "1px solid #f0f0f0" }}
             loading={loading}
             pagination={false}
             dataSource={filteredDealOpportunities()} />
    </div>
  )
}

export default TableLastSales;
