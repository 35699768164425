import { useStore } from "react-redux";
import { useState } from "react";
import CustomerHttp from "../../../../http/requests/CustomerHttp";
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select} from "antd";
import MaskInput from "../../../../utils/components/MaskInput/MaskInput";
import LegalTable from "./components/LegalTable/LegalTable";
import isValidCNPJ from "../../../../utils/functions/cnpjValidation";
import AlertToast from "../../../../utils/components/AlertToast/AlertToast";

const { Option } = Select;

function LegalCustomer() {
  const store = useStore();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [alert, setAlert] = useState({ message: "", show: false, type: "", onClose: () => {} });
  const [resetTable, setResetTable] = useState(1);

  const { id } = store.getState().userStore;

  const handleCancel = () => {
    setIsModalVisible(false);
  }

  const handleSubmit = (values) => {
    setFormLoading(true);
    const data = {
      ...values,
      startDate: values.startDate.format('YYYY-MM-DD'),
      partnerId: id,
      personType: "PJ"
    }
    CustomerHttp.createCustomer(data)
      .then(res => {
        setIsModalVisible(false);
        setResetTable(resetTable + 1);
      })
      .catch(err => {
        setAlert({ message: err.response.data.message, show: true, type: "error", onClose: () => setAlert({ ...alert, show: false }) });
        setTimeout(() => {
          setAlert({ ...alert, show: false });
        }, 5000);
      })
      .finally(() => {
        setFormLoading(false);
      });
  }

  return (
    <div style={{ backgroundColor: "#f0f2f5" }} className="h-full">
      <Modal visible={isModalVisible}
             closable={false}
             onCancel={handleCancel}
             footer={null}
             width={1000}>
        <div>
          <Form layout="vertical" requiredMark={true} onFinish={handleSubmit}>
            <Row gutter={24}>

              <Col span={12}>
                <Form.Item label="Razão Social" name="name" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
                  <Input size="large"/>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="E-mail" name="email" rules={[
                  {
                    type: 'email',
                    message: 'E-mail inválido!',
                  },
                  {
                    required: true,
                    message: 'Campo obrigatório!',
                  },
                ]}>
                  <Input size="large"/>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Telefone" name="phone" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
                  <MaskInput mask="(99) 99999-9999"/>
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item label="Início de atividades"
                           name="startDate"
                           rules={[{ required: true, message: 'Campo obrigatório!' }]}>
                  <DatePicker size="large"/>
                </Form.Item>
              </Col>

              <Col span={7}>
                <Form.Item label="CNPJ"
                           name="cnpj"
                           rules={[
                             {
                               validator: (_, value) => {
                                 if(!value) return Promise.reject(new Error('Campo obrigatório!'));
                                 if(value.match(new RegExp(/[0-9]/g)) &&
                                   value.match(new RegExp(/[0-9]/g)).length === 14) {
                                   return isValidCNPJ(value) ? Promise.resolve() : Promise.reject(new Error('CNPJ inválido!'));
                                 }
                                 Promise.resolve();
                               }
                             }
                           ]}>
                  <MaskInput mask="99.999.999/9999-99"/>
                </Form.Item>
              </Col>

              <Col span={5}>
                <Form.Item label="Tipo"
                           name="companyType"
                           required
                           rules={[{ required: true, message: 'Campo obrigatório!' }]}>
                  <Select size="large">
                    <Option value="LTDA">LTDA</Option>
                    <Option value="Eireli">Eireli</Option>
                    <Option value="MEI">MEI</Option>
                    <Option value="ME">ME</Option>
                    <Option value="EPP">EPP</Option>
                    <Option value="EI">EI</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item>
                  <div className="flex justify-center">
                    <Button style={{ padding: "0 45px", borderRadius: "8px" }}
                            size="large"
                            type="primary"
                            htmlType="submit"
                            loading={formLoading}>
                      Salvar
                    </Button>
                  </div>
                </Form.Item>
              </Col>

            </Row>
          </Form>
        </div>
        <AlertToast show={alert.show} message={alert.message} type={alert.type} onClose={alert.onClose}/>
      </Modal>

      <div className="px-10 py-5" style={{ backgroundColor: "#f0f2f5" }}>
        <h1 className="text-2xl font-semibold text-primary mb-2">Gere novas oportunidades</h1>
        <p className="text-gray-500 text-lg">
          Compare os produtos disponíveis para cada cliente
        </p>
      </div>

      <div className="px-10 flex justify-end">
        <Button type="primary"
                style={{ padding: "0 35px", borderRadius: "8px" }}
                size="large"
                onClick={() => setIsModalVisible(true)}>Adicionar cliente</Button>
      </div>

      <div className="px-10 mt-10">
        <LegalTable reset={resetTable} />
      </div>

    </div>
  )
}

export default LegalCustomer;
