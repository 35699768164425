import { Menu } from "antd";
import { useLocation, Link } from "react-router-dom";
import DashboardIcon from "../../../../assets/svg/DashboardIcon";
import CustomersIcon from "../../../../assets/svg/CustomersIcon";
import ProductsIcon from "../../../../assets/svg/ProductsIcon";

const { SubMenu } = Menu;

function SideBar() {
  const location = useLocation();

  return (
    <Menu mode="inline" theme="dark" defaultSelectedKeys={location.pathname}>
      <Menu.Item key="/home" icon={DashboardIcon}>
        <Link to="/home">
          Dashboard
        </Link>
      </Menu.Item>
      <SubMenu key="sub1" icon={CustomersIcon} title="Clientes">
        <Menu.Item key="1">
          <Link to="/home/customers/physical">
            Pessoas
          </Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to="/home/customers/legal">
            Empresas
          </Link>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="/home/products" icon={ProductsIcon}>
        <Link to="/home/products">
          Produtos
        </Link>
      </Menu.Item>
    </Menu>
  )
}

export default SideBar;
