import { Link, useParams } from "react-router-dom";
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import EditIcon from "../../../../../../../../assets/svg/EditIcon";
import { Button, Image, Table, Steps, Popover, Form, Row, Col, Input, Select, Modal, InputNumber } from "antd";
import { useEffect, useState } from "react";
import PartnerHttp from "../../../../../../../../http/requests/PartnerHttp";
import DeleteIcon from "../../../../../../../../assets/svg/DeleteIcon";
import AlertToast from "../../../../../../../../utils/components/AlertToast/AlertToast";
import WarningIcon from "../../../../../../../../assets/svg/WarningIcon";

const { Step } = Steps;
const { Column } = Table;
const { Option } = Select;

function DealInfo({ url }) {
  let { dealId } = useParams();
  const [dealOpportunity, setDealOpportunity] = useState(null);
  const [fields, setFields] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [proposalsStep, setProposalsStep] = useState({});
  const [alert, setAlert] = useState({ message: "", show: false, type: "", onClose: () => {} });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [inputsToForm, setInputsToForm] = useState([]);
  const [proposalIdSelected, setProposalIdSelected] = useState('');

  const makeFields = (productTypeFields) => {
    const fieldsColumns = productTypeFields.map(elem => (
      {
        title: elem.label.toUpperCase(),
        dataIndex: elem.name,
        key: elem.name
      }));
    setFields(fieldsColumns);
  }

  const proposalStages = {
    simulation: { label: "Simulação", step: 0},
    review: { label: "Análise", step: 1},
    approval: { label: "Aprovação", step: 2},
    finish: { label: "Finalizado", step: 3}
  }

  const proposalStagesNumber = {
    0: { label: "Simulação", step: 0, name: "simulation" },
    1: { label: "Análise", step: 1, name: "review" },
    2: { label: "Aprovação", step: 2, name: "approval" },
    3: { label: "Finalizado", step: 3, name: "finish" }
  }

  const getDeal = () => {
    PartnerHttp.getDealOpportunitieById(dealId)
      .then(res => {
        setDealOpportunity(res.data);
        makeFields(res.data.product_type.fields);
        let proposalStep = {};
        const mappedData = res.data.proposals.map(elem => {
          proposalStep = { ...proposalStep, [elem.id.replaceAll('-', '')]: proposalStages[elem.stage].step};
          const fields = elem.product.fields.reduce((acc, field) => ({
            ...acc,
            [field.name]: field.unit ? field.value + ' ' + field.unit : field.value
          }), {});
          return {
            key: elem.id,
            institution: elem.product.financial_institution.avatar,
            product: elem.name,
            ...fields,
            steps: elem.id,
            delete: elem.id,
            thereArePendingIssues: elem.there_are_pending_issues,
            inputs: { inputs: elem.inputs, proposalId: elem.id }
          }
        });
        setProposalsStep(proposalStep);
        setProducts(mappedData);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  }

  useEffect(() => {
    getDeal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ]);

  const customDot = (dot, { index }) => {
    return (
      <Popover
        content={
          <span>
        {proposalStagesNumber[index].label}
      </span>
        }
      >
        {dot}
      </Popover>
    )
  };

  const changeProposalStage = (proposalId, stage) => {
    PartnerHttp.changeStageProposalId(proposalId, { stage: proposalStagesNumber[stage].name })
      .then(res => {
        let newStep = { ...proposalsStep, [proposalId.replaceAll('-', '')]: stage };
        setProposalsStep(newStep);
      })
      .catch(err => {
        setAlert({ message: "Ocorreu um erro ao mudar a etapa!", show: true, type: "error", onClose: () => setAlert({ ...alert, show: false }) });
        return setTimeout(() => {
          setAlert({ ...alert, show: false });
        }, 5000);
      })
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  }

  const handleSubmit = (data) => {
    setFormLoading(true);
    let newData = { inputs: inputsToForm.map(elem => ({ ...elem, value: data[elem.name] })) };
    PartnerHttp.updateProposalInputs(proposalIdSelected, newData)
      .then(res => {
        setTableLoading(true);
        getDeal();
        setIsModalVisible(false);
        setAlert({ message: "Formulário enviado com sucesso!", show: true, type: "success", onClose: () => setAlert({ ...alert, show: false }) });
        return setTimeout(() => {
          setAlert({ ...alert, show: false });
        }, 5000);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setFormLoading(false);
      });
  }

  const makeForm = (inputs) => {
    setProposalIdSelected(inputs.proposalId);
    setInputsToForm(inputs.inputs);
    setIsModalVisible(true);
  }

  const inputsType = {
    text: (params) => (
      <Form.Item name={params.name} initialValue={params.value} label={params.label} rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Input size="large"/>
      </Form.Item>
    ),
    array: (params) => (
      <Form.Item name={params.name} initialValue={params.value} label={params.label} rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select size="large">
          {params.options.map(elem => <Option value={elem}>{elem}</Option>)}
        </Select>
      </Form.Item>
    ),
    float: (params) => (
      <Form.Item name={params.name} initialValue={params.value} label={params.label} rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <InputNumber style={{ width: "100%"}}
                     size="large"
                     formatter={value => `R$ ` + value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                     parser={value => value.replace(/R\$\s?|(,*)/g, '')}  />
      </Form.Item>
    ),
    int: (params) => (
      <Form.Item name={params.name} initialValue={params.value} label={params.label} rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <InputNumber style={{ width: "100%"}} size="large" />
      </Form.Item>
    )
  }

  return (
    <div>
      <Modal visible={isModalVisible}
             closable={false}
             onCancel={handleCancel}
             footer={null}
             width={1000}
             destroyOnClose={true}>
        <div>
          <Form layout="vertical" requiredMark={true} onFinish={handleSubmit} >
            <Row gutter={24}>

              {inputsToForm.map(elem => {
                return (
                  <Col span={12}>
                    {inputsType[elem.type](elem)}
                  </Col>
                )
              })}

              <Col span={24}>
                <Form.Item>
                  <div className="flex justify-center mt-5">
                    <Button style={{ padding: "0 45px", borderRadius: "8px" }}
                            size="large"
                            type="primary"
                            htmlType="submit"
                            loading={formLoading}>
                      Salvar
                    </Button>
                  </div>
                </Form.Item>
              </Col>

            </Row>
          </Form>
        </div>
        <AlertToast show={alert.show} message={alert.message} type={alert.type} onClose={alert.onClose}/>
      </Modal>
      <div style={{ position: "fixed", top: "30px", left: "55%" }}>
        <AlertToast show={alert.show} message={alert.message} type={alert.type} onClose={alert.onClose}/>
      </div>
      <div className="w-20">
        <Link to={url}>
          <p className="flex items-center text-gray-900 hover:underline">
            <ArrowLeftOutlined /> <span className="ml-2">Voltar</span>
          </p>
        </Link>
      </div>
      <div className="flex justify-between mt-7">
        <div>
          <p className="text-gray-500">DATA DE CRIAÇÃO</p>
          <p className="m-0">{dealOpportunity ? dealOpportunity.created_at.split('T')[0].split('-').reverse().join('/') : <LoadingOutlined />}</p>
        </div>
        <div>
          <p className="text-gray-500">TIPO DE PRODUTO</p>
          <p className="m-0 underline">{dealOpportunity ? dealOpportunity.product_type.name : <LoadingOutlined />}</p>
        </div>
        <div>
          <p className="text-gray-500">VALOR</p>
          <p className="m-0">{dealOpportunity ? "R$ " + dealOpportunity.desired_amount : <LoadingOutlined />}</p>
        </div>
        <div>
          <p className="text-gray-500">STATUS</p>
          <p className="m-0">{dealOpportunity ? dealOpportunity.status : <LoadingOutlined />}</p>
        </div>
        <div className="flex items-end">
          <Button type="link" onClick={() => console.log('foi')}>
            {EditIcon}
          </Button>
        </div>
      </div>
      <div className="mt-10">
        <p className="font-bold text-lg mb-5">Propostas</p>
        <Table style={{ marginTop: "30px" }}
               pagination={false}
               loading={tableLoading}
               dataSource={products}
               expandable={{
                 defaultExpandAllRows: true,
                 expandedRowRender: record => {
                   return (
                     <div style={{ maxWidth: 400, backgroundColor: "#FBF3ED", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                          className="rounded flex items-center justify-center p-10 flex-col mx-auto">
                       {WarningIcon}
                       <p className="py-5">Informações pendentes!</p>
                       <Button type="primary" size="large" onClick={() => makeForm(record.inputs)}>Concluir Cadastro</Button>
                     </div>
                   )
                 },
                 rowExpandable: record => record.thereArePendingIssues,
               }}>
          <Column title="INSTITUIÇAO"
                  dataIndex="institution"
                  render={(text) => {
                    return (
                      <div className="bg-white flex items-center shadow-md" style={{ height: "100px", width: "100px" }}>
                        <Image preview={false} src={process.env.REACT_APP_AWS_S3 + text} />
                      </div>
                    )
                  }}
          />

          {fields.map(elem => <Column title={elem.title} dataIndex={elem.dataIndex} />)}

          <Column title="ETAPA DO PROCESSO" dataIndex="steps" render={(proposalId) => {
            const proposalIdNumber = proposalId.replaceAll('-', '')

            return (
              <Steps current={proposalsStep[proposalIdNumber]} progressDot={customDot} onChange={(step) => {
                changeProposalStage(proposalId, step);
              }}>
                <Step/>
                <Step/>
                <Step/>
                <Step/>
              </Steps>
            )
          }} />

          <Column title="" dataIndex="delete" render={(proposalId) => (
            <Button icon={DeleteIcon} type="link" />
          )} />

        </Table>
      </div>
    </div>
  )
}

export default DealInfo;
