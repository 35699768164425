import { Button, Checkbox, Form, Image, Input, InputNumber, Modal, Select, Table } from "antd";
import { useEffect, useState } from "react";
import ProductTypeHttp from "../../../../../../http/requests/ProductTypeHttp";
import ProductHttp from "../../../../../../http/requests/ProductHttp";
import AlertToast from "../../../../../../utils/components/AlertToast/AlertToast";
import PartnerHttp from "../../../../../../http/requests/PartnerHttp";
import { useStore } from "react-redux";
import { CheckCircleFilled } from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;
const { Column } = Table;

function ProductsTab(props) {
  const [productTypes, setProductTypes] = useState([]);
  const [productTypesLoading, setProductTypesLoading] = useState(true);
  const [productTypesSelected, setProductTypesSelected] = useState("");
  const [tableLoading, setTableLoading] = useState(true);
  const [fields, setFields] = useState([]);
  const [products, setProducts] = useState();
  const [productsFiltered, setProductsFiltered] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [form] = Form.useForm();
  const [alert, setAlert] = useState({ message: "", show: false, type: "", onClose: () => {} });
  const [formLoading, setFormLoading] = useState(false);
  const store = useStore();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { id } = store.getState().userStore;
  const { customerId } = props;

  const makeFields = (productTypeFields) => {
    const fieldsColumns = productTypeFields.map(elem => (
      {
        title: elem.label.toUpperCase(),
        dataIndex: elem.name,
        key: elem.name
      }));
    setFields(fieldsColumns);
  }

  const getProducts = (productTypeSelected) => {
    ProductHttp.getAll()
      .then(res => {
        setProducts(res.data.data);
        const data = res.data.data.filter(elem => elem.type.id === productTypeSelected)
        const mappedData = data.length
          ? data.map(elem => {
            const fields = elem.fields.reduce((acc, field) => ({
              ...acc,
              [field.name]: field.unit ? field.value + ' ' + field.unit : field.value
            }), {});
            return {
              key: elem.id,
              institution: elem.financialInstitution.avatar,
              product: elem.name,
              ...fields,
              select: elem.id
            }
          })
          : data;
        setProductsFiltered(mappedData);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setTableLoading(false);
      })
  }

  const makeInputs = (productTypeInputs) => {
    form.resetFields();
    setInputs(productTypeInputs);
  }

  const getProductTypes = () => {
    ProductTypeHttp.getAll()
      .then(res => {
        let data = res.data.data.map(elem => ({ label: elem.name, value: elem.id, fields: elem.fields, inputs: elem.inputs }));
        setProductTypes(data);
        if(data.length) {
          setProductTypesSelected(data[0].value);
          makeFields(data[0].fields);
          makeInputs(data[0].inputs);
          getProducts(data[0].value);
        } else {
          setTableLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setProductTypesLoading(false);
      });
  }

  const filteredProducts = (value) => {
    setTableLoading(true);
    let filteredProducts;
    setProductTypesSelected(value);
    filteredProducts = products.filter(elem => {
      return elem.type.id === value;
    });
    filteredProducts = filteredProducts.length
      ? filteredProducts.map(elem => {
        const fields = elem.fields.reduce((acc, field) => ({ ...acc, [field.name]: field.unit ? field.value + ' ' + field.unit : field.value }), {});
        return {
          key: elem.id,
          institution: elem.financialInstitution.avatar,
          product: elem.name,
          ...fields,
          select: elem.id
        }
      })
      : filteredProducts;
    setTableLoading(false);
    setProductsFiltered(filteredProducts);
  }

  const handleSubmit = (data) => {
    if(selectedProducts.length === 0) {
      setAlert({ message: "Selecione pelo menos um produto!", show: true, type: "warning", onClose: () => setAlert({ ...alert, show: false }) });
      return setTimeout(() => {
        setAlert({ ...alert, show: false });
      }, 5000);
    }
    setFormLoading(true);
    let inputsArray = [];
    for (const elem in data.inputs) {
      inputsArray.push({ name: elem, value: data.inputs[elem] });
    }
    const newData = {
      ...data,
      inputs: inputsArray,
      products: selectedProducts.map(elem => ({ id: elem })),
      customer_id: customerId,
      partner_id: id,
      product_type_id: productTypesSelected
    };
    PartnerHttp.createDealOpportunities(newData)
      .then(res => {
        setIsModalVisible(true);
      })
      .catch(err => {
        setAlert({ message: "Ocorreu um erro!", show: true, type: "error", onClose: () => setAlert({ ...alert, show: false }) });
        return setTimeout(() => {
          setAlert({ ...alert, show: false });
        }, 5000);
      })
      .finally(() => setFormLoading(false));
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  }

  useEffect(() => {
    getProductTypes();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [ ]);

  const inputsType = {
    text: (params) => (
      <Form.Item name={['inputs', params.name]} rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Input style={{ width: 200 }} size="large" />
      </Form.Item>
    ),
    select: (params) => (
      <Form.Item name={['inputs', params.name]} rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select style={{ width: 200 }} size="large">
          {params.options.map(elem => <Option value={elem.value}>{elem.label}</Option>)}
        </Select>
      </Form.Item>
    ),
    number: (params) => (
      <Form.Item name={['inputs', params.name]} rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <InputNumber style={{ width: 200 }} size="large" />
      </Form.Item>
    )
  }

  return (
    <div>
      <Modal visible={isModalVisible}
             closable={false}
             onCancel={handleCancel}
             footer={null}
             width={750}>
        <div className="flex justify-center p-10 flex-col">
          <CheckCircleFilled style={{ color: "#35C232", fontSize: "110px" }} />
          <p className="text-4xl text-center mt-4 font-bold">Negócio criado com sucesso!</p>
          <p className="text-center m-auto text-xl w-3/4">Complete o cadastro para prosseguir com a análise ou encaminhe as opções escolhidas na tela de negócios.</p>
          <div className="mt-10 flex justify-center">
            <Button type="primary" size="large" style={{ width: "300px" }} onClick={() => {
              setIsModalVisible(false);
              form.resetFields();
            }}>
              OK
            </Button>
          </div>
        </div>
      </Modal>
      <div style={{ position: "fixed", top: "30px", left: "55%" }}>
        <AlertToast show={alert.show} message={alert.message} type={alert.type} onClose={alert.onClose}/>
      </div>
      <Form form={form} requiredMark={true} onFinish={handleSubmit}>
        <div className="flex">
          <div>
            <p className="font-bold mb-2 text-base">Tipo de produto</p>
              <Select
                style={{ width: 200, marginRight: "30px" }}
                size="large"
                loading={productTypesLoading}
                options={productTypes}
                value={productTypesSelected}
                onChange={value => {
                  filteredProducts(value);
                  makeFields(productTypes.filter(elem => elem.value === value)[0].fields);
                  makeInputs(productTypes.filter(elem => elem.value === value)[0].inputs);
                }}
              />
          </div>
          <div>
            <p className="font-bold mb-2 text-base">Valor do produto</p>
            <Form.Item name="desired_amount" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
              <InputNumber size="large"
                           style={{ width: 200 }}
                           placeholder="Valor do produto (R$)"
                           formatter={value => `R$ ` + value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                           parser={value => value.replace(/R\$\s?|(,*)/g, '')} />
            </Form.Item>
          </div>
        </div>
        <div>
          <p className="font-bold mb-2 text-base">Descrição</p>
          <Form.Item name="reason" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
            <TextArea rows={4} style={{ width: 430 }} />
          </Form.Item>
        </div>
        <div className="mt-5 flex flex-wrap" style={{ gap: "30px" }}>
          {inputs.map(elem =>
            <div>
              <p className="font-bold mb-2 text-base">{elem.label}</p>
              {inputsType[elem.type](elem)}
            </div>
          )}
        </div>
        <Table style={{ marginTop: "30px" }}
               pagination={false}
               dataSource={productsFiltered}
               loading={tableLoading}>
          <Column title="INSTITUIÇAO"
                  dataIndex="institution"
                  render={(text) => {
                    return (
                      <div className="bg-white flex items-center shadow-md" style={{ height: "100px", width: "100px" }}>
                        <Image preview={false} src={process.env.REACT_APP_AWS_S3 + text} />
                      </div>
                    )
                  }}
          />
          <Column title="PRODUTO" dataIndex="product" />

          {fields.map(elem => <Column title={elem.title} dataIndex={elem.dataIndex} />)}

          <Column title="SELECIONAR" dataIndex="select" render={(value) => {
            return (
              <>
                <Checkbox value={value} onChange={(e) => {
                  if(e.target.checked) {
                    return setSelectedProducts([...selectedProducts, e.target.value])
                  }
                  setSelectedProducts(selectedProducts.filter(elem => elem !== e.target.value));
                }} />
              </>
            )
          }}/>
        </Table>

        <div className="w-1/5 mx-auto mt-10">
          <Button type="primary" htmlType="submit" size="large" loading={formLoading} block>Criar negócio</Button>
        </div>
      </Form>
    </div>
  )
}

export default ProductsTab;
