import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import CustomerHttp from "../../../../http/requests/CustomerHttp";
import { Avatar, Tabs } from "antd";
// import { BankOutlined } from '@ant-design/icons';
import ProductsTab from "./components/ProductsTab/ProductsTab";
import DealTab from "./components/DealTab/DealTab";

const { TabPane } = Tabs;

function CustomerInfo() {
  const [customer, setCustomer] = useState({});

  const { id } = useParams();

  useEffect(() => {
    CustomerHttp.getCustomer(id)
      .then(res => {
        setCustomer(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [ id ]);

  return (
    <div style={{ backgroundColor: "rgb(240, 242, 245)" }} className="h-full p-10">
      <div className="flex items-center">
        <Avatar style={{ color: "white", backgroundColor: "#fbbf24", marginRight: "20px" }} size={64}>TF</Avatar>
        <p className="text-primary font-semibold m-0 uppercase text-lg">{customer.name ?? "..."}</p>
      </div>
      <div className="flex mt-6">
        <div className="flex-grow-0 w-2/12">
          <p className="text-gray-500">{customer.email ?? '...'}</p>
          <p className="text-gray-500">{customer.phone ?? '...'}</p>
          {/*<div className="flex items-center text-lg">*/}
          {/*  <BankOutlined style={{ color: "#6b7280" }}/>*/}
          {/*  <p className="text-primary m-0 ml-4">Empresa</p>*/}
          {/*</div>*/}
        </div>
        <div className="flex-grow">
          <div className="card-container">
            <Tabs type="card">
              <TabPane tab="Produtos disponíveis" key="1">
                <ProductsTab customerId={id} />
              </TabPane>
              <TabPane tab="Negócios" key="2">
                <DealTab customerId={id} />
              </TabPane>
              <TabPane tab="Documentos" key="3" disabled>
                <p>Content of Tab Pane 3</p>
                <p>Content of Tab Pane 3</p>
                <p>Content of Tab Pane 3</p>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomerInfo;
