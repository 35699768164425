import AxiosInstance from "../AxiosInstance";

const PartnerHttp = {
  getSalesData: partnerId => AxiosInstance.get("/partner/dashboard/" + partnerId),
  getDealOpportunities: partnerId => AxiosInstance.get("/partner/deal-opportunities/partner/" + partnerId),
  createDealOpportunities: params => AxiosInstance.post("/partner/deal-opportunities", params),
  cancelDealOpportunity: dealOpportunityId => AxiosInstance.put("/partner/deal-opportunities/" + dealOpportunityId + "/cancel"),
  getDealOpportunitiesByCustomer: customerId => AxiosInstance.get("/partner/deal-opportunities/customer/" + customerId),
  getDealOpportunitieById: dealOpportunityId => AxiosInstance.get("/partner/deal-opportunities/" + dealOpportunityId),
  changeStageProposalId: (proposalId, stage) => AxiosInstance.put("/partner/proposal/" + proposalId + "/change-stage", stage),
  updateProposalInputs: (proposalId, inputs) => AxiosInstance.put("/partner/proposal/" + proposalId, inputs)
}

export default PartnerHttp;
