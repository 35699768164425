import { Switch, Route } from "react-router-dom";
import Dashboard from "./views/Dashboard/Dashboard";
import PhysicalCustomer from "./views/PhysicalCustomer/PhysicalCustomer";
import LegalCustomer from "./views/LegalCustomer/LegalCustomer";
import Products from "./views/Products/Products";
import CustomerInfo from "./views/CustomerInfo/CustomerInfo";

function HomeRoutes() {
  return (
    <Switch>
      <Route exact path="/home" component={Dashboard} />
      <Route path="/home/customers/physical" component={PhysicalCustomer} />
      <Route path="/home/customers/legal" component={LegalCustomer} />
      <Route path="/home/products" component={Products} />
      <Route path="/home/customer/:id" component={CustomerInfo} />
    </Switch>
  )
}

export default HomeRoutes;
