const EditIcon = (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M16.2529 3.45537C16.2302 3.51534 16.2147 3.57939 16.1836 3.63457C16.0681 3.83925 15.9694 4.0591 15.8234 4.23926C15.6409 4.46453 15.418 4.65625 15.2226 4.85249C14.0567 3.67195 12.8966 2.49726 11.7329 1.31897C12.1637 0.880865 12.5524 0.349297 13.2452 0.257751C13.3555 0.257751 13.4658 0.257751 13.576 0.257751C14.0875 0.325835 14.4629 0.621247 14.7982 0.993426C14.9973 1.21428 15.2103 1.42285 15.4244 1.62901C15.7854 1.97663 16.1362 2.32918 16.2528 2.84636C16.2529 3.04931 16.2529 3.25232 16.2529 3.45537Z" fill="#939393"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.883789 15.8499C1.03339 15.3833 1.1808 14.9159 1.33305 14.4502C1.69456 13.3449 2.05834 12.2403 2.4215 11.1355C2.43515 11.094 2.45183 11.0535 2.46239 11.025C3.52331 12.0993 4.58029 13.1695 5.63908 14.2416C5.23545 14.3779 4.81433 14.5204 4.39302 14.6624C3.2734 15.0396 2.15368 15.4166 1.03424 15.7944C0.992634 15.8085 0.95392 15.8312 0.913832 15.8499C0.903786 15.8499 0.893787 15.8499 0.883789 15.8499Z" fill="#939393"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.2527 2.84623C16.1361 2.32911 15.7853 1.9765 15.4243 1.62889C15.2102 1.42272 14.9972 1.21415 14.7981 0.993303C14.4628 0.621124 14.0875 0.325761 13.5759 0.257629C14.4682 0.257629 15.3605 0.257629 16.2527 0.257629C16.2527 1.12054 16.2527 1.98341 16.2527 2.84623Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.7041 5.34272C11.8955 8.18657 9.0864 11.0309 6.28686 13.8656C5.13757 12.7031 3.97942 11.5317 2.83179 10.3709C5.63749 7.52997 8.44911 4.68309 11.2521 1.84494C12.3952 3.00322 13.5526 4.17599 14.7041 5.34272Z" fill="#939393"/>
  </svg>
);

export default EditIcon;
