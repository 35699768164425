const CustomersIconYellow = (
  <svg width="1em" height="1em" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 9.39545C0.0275659 9.28293 0.0524128 9.16962 0.0830729 9.05791C0.356107 8.06366 1.24938 7.34071 2.28127 7.32031C2.79349 7.31014 3.3096 7.34164 3.81896 7.39879C4.15229 7.4362 4.44168 7.6204 4.70918 7.84946C4.66685 7.89789 4.63094 7.94158 4.59236 7.98274C3.83771 8.78618 3.41255 9.74213 3.26863 10.83C3.15138 11.716 3.17726 12.6058 3.19137 13.4956C3.19671 13.8345 3.19226 14.1735 3.19226 14.5338C3.11533 14.5415 3.03666 14.556 2.95795 14.5561C2.33734 14.5571 1.71664 14.5593 1.09607 14.5532C0.546115 14.5479 0.204307 14.2885 0.0297694 13.7603C0.0248 13.7451 0.0101731 13.7331 4.68551e-05 13.7196C-2.57547e-08 12.2782 0 10.8368 0 9.39545Z" fill="#b45309"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.9998 13.6845C17.9874 13.7095 17.9715 13.7334 17.9632 13.7597C17.8165 14.2236 17.4751 14.5252 16.9924 14.5421C16.3082 14.5661 15.6226 14.552 14.9377 14.5525C14.8978 14.5525 14.858 14.5377 14.801 14.5263C14.801 14.4625 14.801 14.3999 14.801 14.3374C14.8012 13.43 14.8183 12.5221 14.7971 11.6152C14.772 10.5331 14.544 9.50099 13.9022 8.59942C13.7194 8.34261 13.5116 8.10361 13.3037 7.84197C13.5643 7.60954 13.8766 7.44512 14.2319 7.38118C14.8645 7.26731 15.5066 7.25024 16.1353 7.37124C17.0832 7.5537 17.8077 8.34336 17.9675 9.29837C17.973 9.33156 17.9888 9.36311 17.9998 9.39537C17.9998 10.8251 17.9998 12.2548 17.9998 13.6845Z" fill="#b45309"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.7904 15.6795C7.72433 15.6795 6.65826 15.6824 5.59219 15.6784C4.94631 15.6759 4.48257 15.3577 4.31314 14.8082C4.28243 14.7085 4.25848 14.6021 4.25805 14.4986C4.25393 13.4974 4.22542 12.495 4.26227 11.495C4.32139 9.89072 5.02699 8.6481 6.44444 7.84526C7.02506 7.51644 7.6565 7.35245 8.31841 7.30862C9.08556 7.2578 9.85685 7.24711 10.6067 7.4329C12.2097 7.83008 13.5917 9.4108 13.7004 11.0551C13.7771 12.2158 13.7361 13.3845 13.7342 14.5496C13.734 14.7005 13.6644 14.8553 13.6083 15.0011C13.4797 15.335 13.2303 15.548 12.8771 15.5816C12.2898 15.6376 11.6996 15.6786 11.1099 15.6927C10.3371 15.7111 9.56366 15.6972 8.7904 15.6972C8.7904 15.6913 8.7904 15.6854 8.7904 15.6795Z" fill="#b45309"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.00048 1.46052e-07C10.6953 0.000515836 12.0599 1.36611 12.0582 3.05987C12.0565 4.74659 10.6747 6.12203 8.98988 6.11406C7.307 6.10609 5.94258 4.73811 5.94141 3.05762C5.94014 1.36466 7.30606 -0.000515544 9.00048 1.46052e-07Z" fill="#b45309"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M2.8063 2.25007C3.87575 2.24628 4.74281 3.10588 4.74571 4.1728C4.74862 5.23835 3.88737 6.10579 2.81905 6.11343C1.76011 6.12103 0.882356 5.24862 0.879403 4.18555C0.876355 3.11835 1.73709 2.25382 2.8063 2.25007Z" fill="#b45309"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.1838 2.25007C16.2536 2.24777 17.1191 3.10803 17.1208 4.17528C17.1224 5.24046 16.2582 6.10869 15.1916 6.11342C14.1313 6.11821 13.2562 5.24632 13.2544 4.18306C13.2525 3.11596 14.1146 2.25232 15.1838 2.25007Z" fill="#b45309"/>
  </svg>
)

export default CustomersIconYellow;
