import { defineState } from "redux-localstore";

const initialState = defineState({ })("userStore");

function UserReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_USER_DATA':
      return state = action.data;
    default:
      return state;
  }
}

export default UserReducer;
