import logoIcon from '../../assets/img/bankfy-roxo.png';
import { Form, Input, Button, Checkbox } from 'antd';
import { LockFilled } from "@ant-design/icons";
import { useState } from "react";
import AuthHttp from "../../http/requests/AuthHttp";
import AlertToast from "../../utils/components/AlertToast/AlertToast";
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import AxiosInstance from "../../http/AxiosInstance";
import UserHttp from "../../http/requests/UserHttp";

function Login() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alert, setAlert] = useState({ message: "", show: false, type: "", onClose: () => {} });
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogin = data => {
    setIsSubmitting(true);
    AuthHttp.getToken({ email: data.email, password: data.password })
      .then(async res => {
        dispatch({ type: "SET_AUTH_TOKEN", data: res.data.token.token });
        AxiosInstance.defaults.headers["Authorization"] = "Bearer " + res.data.token.token;
        const userInfo = await UserHttp.getUser(res.data.data.domain_id);
        if (userInfo.data.data.length !== 0) {
          dispatch({ type: "SET_USER_DATA", data: userInfo.data.data })
          history.push('/home');
        } else {
          setAlert({ message: "Usuário não encontrado!", show: true, type: "error", onClose: () => setAlert({ ...alert, show: false }) });
          setTimeout(() => {
            setAlert({ ...alert, show: false });
          }, 5000);
        }
      })
      .catch(err => {
        setAlert({ message: err.response.data.error, show: true, type: "error", onClose: () => setAlert({ ...alert, show: false }) });
        setTimeout(() => {
          setAlert({ ...alert, show: false });
        }, 5000);
      })
      .finally(() => {
        setIsSubmitting(false);
      })
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <AlertToast show={alert.show} message={alert.message} type={alert.type} onClose={alert.onClose}/>
      <div className="max-w-lg w-full space-y-8">
        <div>
          <img className="mx-auto h-14 w-auto" src={logoIcon} alt="Workflow" />
          <h2 className="mt-5 text-center text-2xl font-bold text-gray-700">
            Faça login em sua conta
          </h2>
        </div>

        <div className="flex flex-col bg-white rounded-lg shadow-lg p-12">

          <Form
            layout="vertical"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={handleLogin}
            hideRequiredMark={true}
          >
            <Form.Item
              label="E-mail"
              name="email"
              rules={[{ required: true, message: 'E-mail obrigatório!', type: 'email' }]}
            >
              <Input size="large" placeholder="seu@email.com" />
            </Form.Item>

            <Form.Item
              label="Senha"
              name="password"
              rules={[{ required: true, message: 'Senha Obrigatória!' }]}
            >
              <Input.Password size="large" placeholder="Senha"/>
            </Form.Item>

            <Form.Item name="remember">
              <Checkbox>Lembrar-me</Checkbox>
            </Form.Item>

            <Form.Item>
              <Button type="primary"
                      htmlType="submit"
                      block
                      icon={<LockFilled />}
                      size="large"
                      loading={isSubmitting}
                      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                Entrar
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Login;
