import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Login from "./views/Login/Login";
import GuardedRoute from "./GuardedRoute";
import Home from "./views/Home/Home";

function AppRoutes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route exact path="/login" component={Login} />
        <GuardedRoute path="/home" component={Home} />
        {/*<Route path="/notfound" component={NotFound} />*/}
        {/*<Route path="*">*/}
        {/*  <Redirect to="/notfound" />*/}
        {/*</Route>*/}
      </Switch>
    </Router>
  )
}

export default AppRoutes;
