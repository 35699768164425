import { Switch, Route, useRouteMatch } from "react-router-dom";
import DealList from "./views/DealList/DealList";
import DealInfo from "./views/DealInfo/DealInfo";

function DealTabRoutes({ customerId }) {

  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={() => <DealList customerId={customerId} />} />
      <Route path={path + "/deal-opportunity/:dealId"} component={() => <DealInfo url={url} />} />
    </Switch>
  )
}

export default DealTabRoutes;
