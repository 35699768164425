import HomeRoutes from "./HomeRoutes";
import { Layout, Input } from "antd";
import logoBankfy from "../../assets/img/bankfy-logo-branco.png";
import SideBar from "./components/SideBar/SideBar";
import UserAvatar from "./components/UserAvatar/UserAvatar";
import BadgeNotification from "./components/BadgeNotification/BadgeNotification";
import BadgeNews from "./components/BadgeNews/BadgeNews";
import { SearchOutlined } from "@ant-design/icons";

const { Header, Content, Sider } = Layout;

function Home() {

  return (
    <div>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider style={{ backgroundColor: "#440099" }}>
          <div className="px-5">
            <img src={logoBankfy} alt="logo bankfy"/>
          </div>
          <SideBar />
        </Sider>
        <Layout>
          <Header className="site-layout-background" style={{ backgroundColor: "white" }}>
            <div className="flex justify-end items-center h-full">
              <Input placeholder="Pesquisar" style={{ width: "300px", marginRight: "20px" }} prefix={<SearchOutlined />} />
              <BadgeNews />
              <BadgeNotification />
              <UserAvatar />
            </div>
          </Header>
          <Content className="bg-white">
            <div className="site-layout-background" style={{ height: "100%" }}>
              <HomeRoutes />
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  )
}

export default Home;
