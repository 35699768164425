import { Avatar, Button, Table } from "antd";
import { useEffect, useState } from "react";
import { useStore } from "react-redux";
import CustomerHttp from "../../../../../../http/requests/CustomerHttp";
import { RightOutlined } from "@ant-design/icons";
import {Link} from "react-router-dom";

function PhysicalTable({reset}) {
  const store = useStore();
  const [loading, setLoading] = useState(true);
  const [physicalCustomers, setPhysicalCustomers] = useState();

  const { id } = store.getState().userStore;

  useEffect(() => {
    CustomerHttp.getCustomers({ partnerId: id, customerType: 'PF' })
      .then(res => {
        const customers = res.data.data.map(elem => ({
          key: elem.id,
          name: elem.name,
          cpf: elem.cpf,
          profitability: "R$ --",
          info: elem.id
        }));
        setPhysicalCustomers(customers);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [ id, reset ]);

  const columns = [
    {
      title: 'NOME',
      dataIndex: 'name',
      key: 'name',
      render: name => {
        const colorArray = ["#fbbf24", "#f87171", "#a78bfa", "#60a5fa"];
        const getAvatarColor = () => colorArray[Math.floor((Math.random() * colorArray.length))];
        const arrayNameUser = name.split(" ");
        const getInitials = () => {
          return arrayNameUser[0].substr(0, 1) +
            arrayNameUser[arrayNameUser.length - 1].substr(0, 1)
        }
        const color = getAvatarColor();
        return (
          <div className="flex items-center">
            <Avatar style={{ color: "white", backgroundColor: color, marginRight: "20px" }} size={64}>{getInitials()}</Avatar>
            <p className="text-gray-600 font-semibold m-0 uppercase text-lg">{name}</p>
          </div>
        )
      }
    },
    {
      title: 'CPF',
      dataIndex: 'cpf',
      key: 'cpf',
    },
    {
      title: 'RENTABILIDADE',
      key: 'profitability',
      dataIndex: 'profitability'
    },
    {
      title: '',
      dataIndex: 'info',
      render: customerId => {
        return (
          <Link to={"/home/customer/" + customerId}>
            <Button shape="circle"
                    icon={<RightOutlined />}
                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    size="large"
            />
          </Link>
        )
      }
    }
  ];

  return (
    <div>
      <Table loading={loading} columns={columns} style={{ border: "1px solid #f0f0f0" }} pagination={false} dataSource={physicalCustomers} />
    </div>
  )
}

export default PhysicalTable;
