import './App.less';
import 'antd/dist/antd.less';
import AppRoutes from "./AppRoutes";
import AxiosInstance from "./http/AxiosInstance";
import { useStore } from "react-redux";

function App() {
  const store = useStore();

  const { auth } = store.getState().authStore;

  if(auth) AxiosInstance.defaults.headers["Authorization"] = "Bearer " + auth;

  return (
    <div>
      <AppRoutes />
    </div>
  );
}

export default App;
