import { Avatar, Dropdown } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useStore } from "react-redux";
import {Link} from "react-router-dom";

function UserAvatar() {
  const store = useStore();

  const userName = store.getState().userStore.name;

  const menu = (
    <div className="bg-white p-5 shadow-md">
      <ul style={{ color: "#A6A6A6" }}>
        <li className="font-bold mb-3">{ userName }</li>
        {/*<li className="mb-3">Minha conta</li>*/}
        {/*<li>Suporte</li>*/}
        {/*<li>Política de privacidade</li>*/}
        {/*<li className="mb-3">Termos de uso</li>*/}
        <Link to="/login" style={{ color: "#A6A6A6" }} className="hover:underline"><li>Sair</li></Link>
      </ul>
    </div>
  );

  return (
    <div>
      <Dropdown overlay={menu} className="cursor-pointer" trigger="click">
        <Avatar icon={<UserOutlined />}
                style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#A6A6A6" }}/>
      </Dropdown>
    </div>
  )
}

export default UserAvatar;
