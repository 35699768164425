const ProductsIcon = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.14088 0C10.0547 0.45751 10.9681 0.916193 11.8826 1.3723C13.7355 2.29651 15.5892 3.2194 17.4426 4.14291C17.4948 4.16893 17.5468 4.19546 17.6346 4.23981C11.8715 4.23981 6.14631 4.23981 0.386719 4.23981C0.456477 4.2002 0.498342 4.17376 0.542457 4.15182C3.3073 2.77614 6.07238 1.40085 8.83713 0.0250814C8.84646 0.0203933 8.85223 0.00848544 8.85964 0C8.95335 0 9.04711 0 9.14088 0Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0 17.3321C0.238061 16.9395 0.471575 16.5441 0.715965 16.1557C0.841652 15.9559 1.03532 15.888 1.27221 15.8881C6.42413 15.8914 11.576 15.8914 16.7279 15.8882C16.9648 15.888 17.1588 15.956 17.2845 16.1555C17.529 16.5439 17.7621 16.9395 18 17.3321C18 17.4258 18 17.5195 18 17.6133C17.917 17.7881 17.7877 17.9166 17.6133 18C11.8711 18 6.12892 18 0.38672 18C0.212042 17.9169 0.0830729 17.7881 0 17.6133C0 17.5195 0 17.4258 0 17.3321Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0 5.30859C6 5.30859 12 5.30859 18 5.30859C18 5.33203 18 5.35547 18 5.37892C17.8044 5.95991 17.5213 6.49262 17.1248 6.96241C17.0568 7.04295 16.9862 7.12129 16.9175 7.20108C16.7869 7.35251 16.6233 7.42137 16.4221 7.41931C16.0006 7.41486 15.5789 7.41795 15.1573 7.41795C10.6602 7.41795 6.16305 7.41636 1.66596 7.42184C1.38988 7.42217 1.18749 7.33305 1.01038 7.12448C0.578041 6.61535 0.212605 6.07111 0.0296757 5.41994C0.0254096 5.4047 0.0101731 5.39251 4.68551e-05 5.37892C-2.57547e-08 5.35543 0 5.33203 0 5.30859Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0 17.6133C0.0830729 17.7881 0.212042 17.9169 0.38672 18C0.257798 18 0.128875 18 0 18C0 17.8711 0 17.7422 0 17.6133Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.6133 18C17.7877 17.9166 17.917 17.7881 18 17.6133C18 17.7422 18 17.8711 18 18C17.8711 18 17.7422 18 17.6133 18Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M5.30121 8.48541C5.30121 10.604 5.30121 12.7104 5.30121 14.8264C4.24166 14.8264 3.18961 14.8264 2.12354 14.8264C2.12354 12.7192 2.12354 10.6086 2.12354 8.48541C3.17451 8.48541 4.23069 8.48541 5.30121 8.48541Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.7036 8.48077C13.7687 8.48077 14.8207 8.48077 15.8817 8.48077C15.8817 10.5953 15.8817 12.7017 15.8817 14.822C14.8282 14.822 13.772 14.822 12.7036 14.822C12.7036 12.7154 12.7036 10.6048 12.7036 8.48077Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.42578 14.8236C7.42578 12.704 7.42578 10.5976 7.42578 8.48193C8.4739 8.48193 9.51423 8.48193 10.5683 8.48193C10.5683 10.59 10.5683 12.7006 10.5683 14.8236C9.52806 14.8236 8.4836 14.8236 7.42578 14.8236Z" fill="white"/>
  </svg>
)

export default ProductsIcon;
