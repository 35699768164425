import { Button, Image, Modal, Select, Table } from 'antd';
import { useEffect, useState } from 'react';
import ProductTypeHttp from '../../../../http/requests/ProductTypeHttp';
import FinancialInstitutionHttp from '../../../../http/requests/FinancialInstitutionHttp';
import ProductHttp from '../../../../http/requests/ProductHttp';
import { WhatsAppOutlined } from "@ant-design/icons";
import YouTube from "react-youtube";

const { Column } = Table;
const { Option } = Select;

function Products() {
  const [productTypes, setProductTypes] = useState([]);
  const [productTypesLoading, setProductTypesLoading] = useState(true);
  const [productTypesSelected, setProductTypesSelected] = useState("");
  const [financialInstitution, setFinancialInstitution] = useState([]);
  const [financialInstitutionsLoading, setFinancialInstitutionsLoading] = useState(true);
  const [financialInstitutionSelected, setFinancialInstitutionSelected] = useState("");
  const [products, setProducts] = useState();
  const [productsFiltered, setProductsFiltered] = useState();
  const [tableLoading, setTableLoading] = useState(true);
  const [fields, setFields] = useState([]);
  const [modalInfo, setModalInfo] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  const makeFields = (productTypeFields) => {
    const fieldsColumns = productTypeFields.map(elem => (
      {
        title: elem.label.toUpperCase(),
        dataIndex: elem.name,
        key: elem.name
      }));
    setFields(fieldsColumns);
  }

  const getProductTypes = () => {
    ProductTypeHttp.getAll()
      .then(res => {
        let data = res.data.data.map(elem => ({ label: elem.name, value: elem.id, fields: elem.fields }));
        setProductTypes(data);
        if(data.length) {
          setProductTypesSelected(data[0].value);
          makeFields(data[0].fields);
          getProducts(data[0].value);
        } else {
          setTableLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setProductTypesLoading(false);
      })
  }

  const getInstitutions = () => {
    FinancialInstitutionHttp.getAll()
      .then(res => {
        let data = res.data.data.map(elem => ({ label: elem.name, value: elem.id }));
        setFinancialInstitution(data);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setFinancialInstitutionsLoading(false);
      })
  }

  const getProducts = (productTypeSelected) => {
    ProductHttp.getAll()
      .then(res => {
        console.log(res);
        setProducts(res.data.data);
        const data = res.data.data.filter(elem => elem.type.id === productTypeSelected)
        const mappedData = data.length
          ? data.map(elem => {
            const fields = elem.fields.reduce((acc, field) => ({
              ...acc,
              [field.name]: field.unit ? field.value + ' ' + field.unit : field.value
            }), {});
            return {
              key: elem.id,
              institution: elem.financialInstitution.avatar,
              product: elem.name,
              ...fields,
              banner: elem.banners[0]
            }
          })
          : data;
        setProductsFiltered(mappedData);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setTableLoading(false);
      })
  }

  const filteredProducts = (value, type) => {
    if(products) {
      setTableLoading(true);
    }
    let filteredProducts;
    if(type === 'productType') {
      setProductTypesSelected(value);
      if(products) {
        filteredProducts = products.filter(elem => {
          return (elem.financialInstitution.id === financialInstitutionSelected  || financialInstitutionSelected === '') && elem.type.id === value
        });
      }
    } else {
      setFinancialInstitutionSelected(value);
      if(products) {
        filteredProducts = products.filter(elem => {
          return (elem.financialInstitution.id === value || value === '') && elem.type.id === productTypesSelected
        });
      }
    }
    if(products) {
      filteredProducts = filteredProducts.length
        ? filteredProducts.map(elem => {
          const fields = elem.fields.reduce((acc, field) => ({ ...acc, [field.name]: field.unit ? field.value + ' ' + field.unit : field.value }), {});
          return {
            key: elem.id,
            institution: elem.financialInstitution.avatar,
            product: elem.name,
            ...fields,
            banner: elem.banners[0]
          }
        })
        : filteredProducts;
      setTableLoading(false);
      setProductsFiltered(filteredProducts);
    }
  }

  useEffect(() => {
    getProductTypes();
    getInstitutions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ]);

  return (
    <div style={{ backgroundColor: "#f0f2f5" }} className="h-full">
      <Modal visible={modalVisible}
             destroyOnClose={true}
             onCancel={() => setModalVisible(false)}
             closable={false}
             style={{ minWidth: "700px", maxWidth: "1500px" }}
             footer={
               [
                 <div className="flex flex-col justify-start">
                   <p className="text-left">Compartilhar</p>
                   <Button shape="circle"
                           icon={<WhatsAppOutlined />}
                           style={
                             {
                               display: "flex",
                               alignItems: "center",
                               justifyContent: "center",
                               backgroundColor: "#25d366",
                               color: "white"
                             }
                           }
                           size="large"
                           onClick={() => {
                              window.open("https://api.whatsapp.com/send?text=" + (modalInfo.type === 'image'
                                ? process.env.REACT_APP_AWS_S3 + modalInfo.image
                                : modalInfo.link
                              ), '_blank')
                            }
                           }/>
                 </div>
               ]}
      >
        {modalInfo.type === 'image'
          ? <img src={process.env.REACT_APP_AWS_S3 + modalInfo.image} alt=""/>
          : <YouTube videoId={modalInfo.link ? modalInfo.link.split('v=')[1] : ''} opts={{ width: "100%" }} />}
      </Modal>

      <div className="px-10 py-5">
        <h1 className="text-2xl font-semibold text-primary mb-2">Encontre o produto ideal</h1>
        <p className="text-gray-500 text-lg">
          Faça uma oferta e conquiste novos clientes
        </p>
      </div>

      <div className="px-10 py-5 flex">

        <div className="mr-10">
          <p className="font-bold mb-2 text-base">Tipo de produto</p>
          <Select
            style={{ width: 200 }}
            size="large"
            loading={productTypesLoading}
            options={productTypes}
            value={productTypesSelected}
            onChange={value => {
              filteredProducts(value, 'productType');
              makeFields(productTypes.filter(elem => elem.value === value)[0].fields);
            }}
          />
        </div>

        <div>
          <p className="font-bold mb-2 text-base">Buscar por instituição</p>
          <Select
            style={{ width: 200 }}
            size="large"
            value={financialInstitutionSelected}
            loading={financialInstitutionsLoading}
            onChange={value => {
              filteredProducts(value);
            }}
          >
            <Option value="" key="1">Todas</Option>
            {financialInstitution.map(elem => <Option value={elem.value} key={elem.value}>{elem.label}</Option>)}
          </Select>
        </div>

      </div>

      <div className="px-10 py-5">
        <Table style={{ border: "1px solid #f0f0f0" }}
               pagination={false}
               dataSource={productsFiltered}
               loading={tableLoading}>
          <Column title="INSTITUIÇAO"
                  dataIndex="institution"
                  render={(text) => {
                    return (
                      <div className="bg-white flex items-center shadow-md" style={{ height: "100px", width: "100px" }}>
                        <Image preview={false} src={process.env.REACT_APP_AWS_S3 + text} />
                      </div>
                    )
                  }}
          />
          <Column title="PRODUTO" dataIndex="product" />

          {fields.map(elem => <Column title={elem.title} dataIndex={elem.dataIndex} />)}

          <Column title="" dataIndex="banner" render={(info) => {
            return (
              <Button type="link" onClick={() => {
                if(info) {
                  setModalInfo(info);
                  setModalVisible(true);
                }
              }}>
                <span className="underline">Saiba mais</span>
              </Button>
            )
          }}/>
        </Table>
      </div>

    </div>
  )
}

export default Products;
