const DeleteIcon = (
  <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.29259 0.450806C8.54954 0.543135 8.83162 0.602353 9.05824 0.734771C9.51805 1.00355 9.71651 1.41425 9.7296 1.89472C9.73345 2.03638 9.73021 2.17814 9.73021 2.34453C9.79973 2.34453 9.8625 2.34453 9.92523 2.34453C10.9013 2.34453 11.8773 2.34396 12.8533 2.34472C13.604 2.34528 14.0424 2.73342 14.0435 3.39661C14.0437 3.51754 14.0469 3.63856 14.0422 3.75936C14.0313 4.03856 13.8124 4.22758 13.4925 4.23762C13.4447 4.23913 13.3967 4.23822 13.3488 4.23822C9.13934 4.23825 4.92986 4.23825 0.720381 4.23822C0.211362 4.23822 0.0259074 4.07657 0.0256917 3.6332C0.0256558 3.55431 0.0255839 3.47543 0.0256917 3.39658C0.0269139 2.73342 0.465225 2.34525 1.21592 2.34472C2.18593 2.34399 3.15598 2.34453 4.12603 2.34453C4.19049 2.34453 4.25498 2.34453 4.35808 2.34453C4.35808 2.12306 4.33467 1.9123 4.36239 1.7068C4.45075 1.05175 4.97052 0.586159 5.71126 0.473722C5.73387 0.470313 5.75486 0.458634 5.77657 0.450837C6.61527 0.450806 7.45393 0.450806 8.29259 0.450806ZM5.41882 2.3343C6.5066 2.3343 7.57345 2.3343 8.64962 2.3343C8.64962 2.16227 8.65893 1.99977 8.64743 1.8384C8.63003 1.59339 8.41312 1.40377 8.13029 1.40115C7.4008 1.3944 6.67113 1.3944 5.94164 1.401C5.65119 1.40362 5.435 1.598 5.42047 1.85163C5.41152 2.00819 5.41882 2.16549 5.41882 2.3343Z" fill="#939393"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.49537 13.7615C6.05209 13.7615 5.63503 13.7615 5.20657 13.7615C5.15682 13.2418 5.10739 12.7294 5.05886 12.217C4.95465 11.1167 4.85083 10.0163 4.74687 8.91596C4.67411 8.14573 4.6048 7.37521 4.5268 6.60539C4.49509 6.2928 4.24946 6.10463 3.93815 6.13478C3.61732 6.1659 3.43061 6.39965 3.46059 6.71483C3.57681 7.93566 3.68814 9.15678 3.80317 10.3777C3.90487 11.4571 4.00915 12.5363 4.11153 13.6157C4.11588 13.6615 4.1121 13.7079 4.1121 13.7619C3.39797 13.7619 2.68732 13.7619 1.95589 13.7619C1.6866 10.9077 1.41746 8.05507 1.14746 5.19324C5.07651 5.19324 8.9914 5.19324 12.9217 5.19324C12.6518 8.05438 12.3827 10.907 12.1133 13.7622C11.3832 13.7622 10.6725 13.7622 9.94739 13.7622C9.97525 13.4515 10.0011 13.149 10.0297 12.8467C10.1202 11.8931 10.2127 10.9397 10.3026 9.98601C10.4052 8.89611 10.5042 7.80599 10.6084 6.71619C10.6386 6.40015 10.4526 6.16606 10.1326 6.13497C9.81995 6.10463 9.57371 6.29201 9.54244 6.60457C9.44549 7.57348 9.3558 8.54298 9.26381 9.5123C9.15988 10.6073 9.05657 11.7025 8.95315 12.7976C8.92306 13.1162 8.89365 13.4349 8.8632 13.7612C8.43478 13.7612 8.01768 13.7612 7.57376 13.7612C7.57376 13.7023 7.57376 13.6417 7.57376 13.5811C7.57376 11.2982 7.5738 9.01527 7.57372 6.73235C7.57369 6.35286 7.37623 6.13336 7.03516 6.13304C6.69488 6.13273 6.49555 6.35356 6.49551 6.73143C6.49544 9.00911 6.49547 11.2868 6.49547 13.5645C6.49537 13.6267 6.49537 13.6888 6.49537 13.7615Z" fill="#939393"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M2.0459 14.7261C5.38432 14.7261 8.69884 14.7261 12.0405 14.7261C11.9908 15.0285 11.9828 15.3289 11.8877 15.6063C11.6685 16.2452 11.0554 16.6095 10.2681 16.6099C8.11268 16.6111 5.95722 16.6156 3.80191 16.6036C3.53561 16.6021 3.25104 16.5454 3.00793 16.4497C2.48514 16.2439 2.19512 15.8611 2.11449 15.3635C2.08084 15.1559 2.06883 14.9456 2.0459 14.7261Z" fill="#939393"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M5.41882 2.33432C5.41882 2.1655 5.41152 2.00821 5.42047 1.85168C5.435 1.59805 5.65118 1.40367 5.94164 1.40105C6.67113 1.39445 7.4008 1.39445 8.13028 1.4012C8.41312 1.40382 8.62999 1.59344 8.64743 1.83845C8.65889 1.99982 8.64962 2.16231 8.64962 2.33435C7.57345 2.33432 6.50656 2.33432 5.41882 2.33432Z" fill="white"/>
  </svg>
)

export default DeleteIcon;
