import AxiosInstance from "../AxiosInstance";

const CustomerHttp = {
  getCustomers: data => AxiosInstance.get("/partner/customer", {
    params: { partner_id: data.partnerId, customer_type: data.customerType }
  }),
  createCustomer: params => AxiosInstance.post("/partner/customer", params),
  getCustomer: customerId => AxiosInstance.get("/partner/customer/" + customerId)
}

export default CustomerHttp;
